import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Hero from '../../components/Hero';
import FeaturedItems from '../../components/FeaturedItems';
import IndexPageComponents from '../../components/IndexPageComponents';
import Layout from '../../components/Layout';
import { HTMLContent } from '../../components/Content';
import useSiteMetadata from '../../components/SiteMetadata';
import MetaComponent from '../../components/MetaComponent';

export const IndexPageTemplate = ({
  heroImageObject,
  heroTitle,
  location,
  featuredItems,
  hotelIds,
  pageComponents,
  propertySearchObject,
  helmet,
  content,
}) => (
  <div className='section container content'>
    {helmet || ''}
    <Hero
      heroImageObject={heroImageObject}
      heroTitle={heroTitle}
      location={location}
      propertySearch={propertySearchObject}
      hotelIds={hotelIds}
      locale='zhCN'
    />
    <div className='index-page-container'>
      <div className='index-page-text'>
      <HTMLContent content={content} className="title" />
      </div>
      <FeaturedItems featuredItems={featuredItems} />
      <IndexPageComponents pageComponents={pageComponents} />
    </div>
  </div>
);

const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  const searchObject = data.searchObject.frontmatter.propertySearchTranslations;
  const propertySearchObject = {
    location: searchObject.location,
    checkIn: searchObject.checkIn,
    checkOut: searchObject.checkOut,
    guests: searchObject.guests,
    placeholder: searchObject.placeholder,
    findingProperties: searchObject.findingProperties,
    noMatch: searchObject.noMatch,
    options: [
      { label: searchObject.options.anywhere, value: 'Anywhere' },
      { label: searchObject.options.niseko, value: 'Niseko' },
      { label: searchObject.options.furano, value: 'Furano' },
    ],
    serviceTypeLabel: searchObject.serviceTypeOptions.serviceTypeLabel,
    serviceTypeOptions: [
      { label: searchObject.serviceTypeOptions.all, value: 'All', info: searchObject.serviceTypeOptions.hakuLifeInformation },
      { label: searchObject.serviceTypeOptions.hakuLife, value: 'Haku Life', info: searchObject.serviceTypeOptions.hakuLifeInformation },
      { label: searchObject.serviceTypeOptions.luxury, value: 'Luxury', info: searchObject.serviceTypeOptions.luxuryInformation },
      { label: searchObject.serviceTypeOptions.signature, value: 'Signature', info: searchObject.serviceTypeOptions.signatureInformation }
    ],
  }
  return (
    <Layout>
      <IndexPageTemplate
        heroImageObject={frontmatter.heroImageObject}
        heroTitle={frontmatter.heroTitle}
        location={location}
        featuredItems={frontmatter.featuredItems}
        propertySearchObject={propertySearchObject}
        hotelIds={data.hotelIds.frontmatter.properties}
        pageComponents={frontmatter.pageComponents}
        content={frontmatter.content}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.heroImageObject.image}`}
          />
        }
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplateZHHANS {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page-zhhans" } }) {
      frontmatter {
        heroTitle
        seoObject {
          metaTitle
          metaDescription
        }
        content
        featuredItems {
          leftImage {
            icon
            textObject {
              title
              text
              subtext
            }
            linkObject {
              link
              isExternal
            }
            isTitle
            imageObject {
              image
              overlay
            }
          }
          middleImage {
            icon
            textObject {
              title
              text
              subtext
            }
            linkObject {
              link
              isExternal
            }
            isTitle
            imageObject {
              image
              overlay
            }
          }
          rightImage {
            icon
            textObject {
              title
              text
              subtext
            }
            linkObject {
              link
              isExternal
            }
            isTitle
            imageObject {
              image
              overlay
            }
          }
        }
        heroImageObject {
          image
          overlay
        }
        pageComponents {
          textComponent
          alignment
          images {
            alignment
            isTitle
            textObject {
              text
              textColor
            }
            linkObject {
              link
              isExternal
            }
            imageObject {
              image
              overlay
            }
          }
        }
      }
    }
    searchObject: markdownRemark(
      frontmatter: { templateKey: { eq: "accommodation-page-zhhans" } }
    ) {
      frontmatter {
        propertySearchTranslations {
          noMatch
          checkOut
          findingProperties
          placeholder
          location
          guests
          checkIn
          luxury
          options {
            anywhere
            niseko
            furano
          }
          serviceTypeOptions {
            serviceTypeLabel
            all
            hakuLife
            luxury
            signature
            hakuLifeInformation
            luxuryInformation
            signatureInformation
          }
        }
      }
    }
    hotelIds: markdownRemark(
      frontmatter: { templateKey: { eq: "rb-properties" } }
    ) {
      frontmatter {
        properties {
          property {
            hotelId
          }
        }
      }
    }
  }
`;
